import '../stylesheets/cats.css'
import '../stylesheets/font-awesome-mine.css'
import '../stylesheets/furtive.css'
import '../stylesheets/furtive_manual.css'
import '../stylesheets/popover.css'
import '../stylesheets/sprites.css'
// import '../stylesheets/dropzone.css'
import '../-stylus/convos.sass'
import '../-stylus/layout.sass'
import '../-stylus/responsive.sass'
import '../-stylus/classic.sass'

import "../javascript/zepto.min2.js"
import "../javascript/zepto-cookie.js"
import "../javascript/jquery.areyousure.js"
import "../javascript/bootstrap-popover.js"
import "../javascript/timefix.js"
import "../javascript/globals.js"
import "../javascript/firebase_setup.js"
import "../-coffee/funcs.coffee"
import "../-coffee/binds.coffee"
import "../-coffee/page.coffee"
import "../-coffee/actions.coffee"

// import "@hotwired/turbo"
// what's the difference?
import "@hotwired/turbo-rails"

import 'altcha' // https://altcha.org/docs/website-integration/

import { registerControllers } from "stimulus-vite-helpers";
import { Application } from "@hotwired/stimulus"
const application = Application.start()
// application.debug = true
const controllers = import.meta.glob( '../javascript/controllers/*_controller.js', { eager: true } )
registerControllers( application, controllers )